.breadcrumb {
  width: auto;
  height: 100px;
  padding: 30px !important;
  margin: -24px;
  margin-bottom: 1rem !important;
  color: #fff !important;
  vertical-align: middle;
  /* background-color: #4e73df; */
}
.breadcrumb-item > a {
  color: #fff !important;
  vertical-align: middle;
  text-decoration: none;
  font-size: large;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ' / ' !important;
  color: rgba(255, 255, 255, 0.445) !important;
  vertical-align: middle;
}
