.small {
  font-weight: 400;
  font-size: 90%;
  font-weight: 400;
  color: #5ca7cc;
  text-decoration: none;
  background-color: transparent;
}

.auth__title {
  font-size: 18px;
  margin-bottom: 5px;
  text-align: center;
}
.recover__description {
  font-size: 14px;
  text-align: left;
}

.logo {
  margin-top: 10px;
  margin-bottom: 25px;
  margin-left: -7px;
}

.login-admin-content {
  max-width: 418px;
}

.text-login {
  color: $primario;
}

.login-button {
  background-color: $secundario2;
  border-color: $secundario2;
  color: #fff;
  cursor: pointer;
  &:hover {
    background-color: lighten($secundario2, 15%);
    border-color: lighten($secundario2, 15%);
    color: #fff;
  }
}

.login-admin-root {
  background: $secundario4;
  height: 100vh;
  display: inline-table;
  padding-top: 30px;
}

.animate__animated.animate__fadeIn.animate__faster.error-message {
  text-align: left;
  color: red;
  font-size: 12px;
  margin-bottom: 2px;
}

.label-form {
  text-align: left;
  margin-top: 10px;
}
