.priorization-container {
  .container {
    max-width: 100%;
  }
  .priorization {
    margin: 0;
    padding: 0;
  }
  .container-fluid {
    padding: 0;
    margin: 0;
  }
}
.priorization {
  .priorization-header {
    .priorization-header_titles {
      p {
        height: 32px;
        font-weight: normal;
        font-size: 24px;
        text-align: left;
        color: #131a27;
      }

      span {
        font-size: 16px;
        font-weight: 500;
        line-height: 5.75;
        text-align: left;
        color: #131a27;
      }
    }

    .priorization-header_calendar {
      display: flex;
      .priorization-calendar {
        display: flex;
        margin-right: 20px;
        margin-bottom: 20px;
        p {
          justify-content: start;
          margin-right: 20px;
        }
        button {
          justify-content: start;
          background-color: #2492d5 !important;
          border: #2492d5;
          margin-left: 50px;
        }
      }
      p {
        justify-content: start;
        font-size: 18px;
        font-weight: normal;
        text-align: left;
        color: #131a27;
      }
    }
  }
}

.input-check-permits {
  padding-left: 13%;
  padding-bottom: 28px;
}

.priorization-banner_container {
  .row {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100px;
    display: flex;
    div {
      width: 50%;
      p {
        margin-top: 6%;
        text-align: right;
        margin-right: 40px;
        font-size: 20px;
      }
      h3 {
        margin-top: 5%;
        margin-left: 40px;
      }
    }
  }
  margin-top: -16px;
  color: #fff;
}

.priorization-user-banner_container {
  .row {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100px;
    display: flex;
    div {
      width: 50%;
      p {
        margin-top: 6%;
        text-align: right;
        margin-right: 40px;
        font-size: 20px;
      }
      h3 {
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin-top: 8px;
        margin-left: 33px;
        display: inline-flex;
        height: 83px;
        line-height: 28px;
        font-size: 25px;
      }
    }
  }
  margin: -17px -26px 46px -25px;
  color: #fff;
}
.priorization-average-banner_container {
  margin-top: -40px;
  margin-bottom: 10px;
}

.priorization-user-banner-right {
  text-align: right;
  padding-right: 56px;
}

// MODAL ** //
// PRIORIZATION DETAIL

.modal {
  margin-top: -20px;
  color: #000;
  width: 100vw;
}

.modal-dialog {
  max-width: 1000px;
}

.modal-footer {
  justify-content: center;
  border-radius: 50px;
  color: #fff;
  height: 40px;
}

.icon_stars-priorization .fa-star {
  margin: 0 3px;
}

.icon_stars-priorization_view {
  margin: auto;
}

.modal-content {
  padding: 10px;
}

.modal-body .row .col-xl-4:nth-child(2) {
  padding-left: 25px;
}

.modal-body .card-detail-content-front {
  color: #fff;
}

.modal-title {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.filter-label {
  color: $secundario1;
  margin-right: 10px;
  margin-bottom: 3px;
}

.custom-select-filter {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
}

.custom-ranking {
  display: block;
  width: 80%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  padding: 0.375rem 30px;
  margin: 8.3% 0;
}

.filter-inline-search {
  color: #131a27;
  font-size: 1.5rem;
  margin: 2rem 4rem 0 0;
}

.modal-footer_priorization {
  margin-top: 15%;
  button {
    border: none;
    margin-top: -18px;
    width: 100%;
    &:focus {
      border: none;
      box-shadow: none;
    }
  }
}

.priorization_form-rating {
  display: flex;
  .priorization_form-section {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }
  .priorization_title-rating {
    color: #131a27;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px !important;
  }
  .priorization_rating-impl {
    margin-top: 50px;
  }
}

.priorization-view {
  span.flashcard-detail-front-gta,
  span.flashcard-detail-front-gta2,
  .flashcard-detail-front-div-solution,
  .flashcard-detail-front-footer-left,
  .flashcard-detail-front-footer-right {
    color: white !important;
  }
}
.priorization {
  .priorization_pagination {
    margin-top: 50px;
    .react-bootstrap-table {
      .table {
        tbody {
          tr {
            td:nth-child(2) {
              width: 230px;
            }
            td:nth-child(3) {
              width: 80px;
            }
          }
        }
      }
    }
  }
}

// Table

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #386496;
  border-color: #386496;
}

.table thead th {
  vertical-align: bottom;
  border: 2px solid #386496;
  background-color: #386496 !important;
  color: #ffffff;
}

table tbody tr:nth-child(odd) {
  background: #fcfcfc;
}
table tbody tr:nth-child(even) {
  background: #fff;
}

.table thead th {
  vertical-align: bottom;
  border: 1px solid transparent;
  background-color: transparent !important;
  color: #386496;
  font-size: 16px;
  border-bottom: 1px solid #ccc;
}

.table > :not(:first-child) {
  border-top: 2px solid #ccc;
}

.table th,
.table td {
  padding: 0.55rem;
  vertical-align: middle !important;
  border-top: 1px solid #e3e6f0;
  font-size: 15px;
  color: #6c6c6c;
}

tbody > tr:hover {
  transition: all 0.1s ease;
  background-color: #f2f2f2 !important;
}
.notifications_table {
  .table tbody tr:hover td,
  .table tbody tr:hover th {
    background-color: white !important;
  }
}

.table-responsive {
  overflow-x: auto;
  padding: 29px 40px 32px 40px !important;
  -webkit-overflow-scrolling: touch;
}

.pagination {
  float: left;
}

.span-total {
  color: #386496;
  margin: 0 1px 0 5px;
  font-weight: 500;
}

.css-79elbk {
  margin-top: 21px;
}

.table th,
.table td {
  padding: 0.55rem;
  vertical-align: middle !important;
  border-top: 1px solid #e3e6f0;
  border-left: none;
  border-right: none;
}

i.fas {
}

.search-bar-input {
  background-color: white;
  width: 300px;
  display: inline;
}
