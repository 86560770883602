// Pallete colors
$primary: #e4e4e4;
$dark-grey: #fff;
$light-grey: #fff;
// $blue       : rgb(240, 240, 240);
$blue: blue;

// Paleta de colores Julia
$primario: #131a27;

// Secundario
$secundario1: #1e3550;
$secundario2: #526988;
$secundario3: #7a9ec6;
$secundario4: #c0d4e8;
$secundario5: #709ec9;

// Generales
$general1: #000000;
$general2: #4d4d4d;
$general3: #b3b3b3;
$general4: #f2f2f2;

// Ejes de trabajo
$objetivo1: #674476;
$objetivo2: #c7af58;
$objetivo3: #427b6d;
$objetivo4: #d84847;
$objetivo5: #50889e;
$objetivo6: #c0529e;
$objetivo7: #6ca8d1;
$objetivo8: #6bb06e;
$objetivo9: #a68467;
$objetivo10: #a39f41;

// GTAs
$gta1: #dc2b44;
$gta2: #e39c3b;
$gta3: #449e52;
$gta4: #bf2c3b;
$gta5: #e63d39;

$gta6: #00b0cd;
$gta7: #fcb526;
$gta8: #6bb06e;
$gta9: #a68467;
$gta10: #a39f41;
$gta11: #674476;
$gta12: #c7af58;
$gta13: #427b6d;
$gta14: #d84847;
$gta15: #50889e;
$gta16: #c0529e;
$gta17: #6ca8d1;
$gta18: #6bb06e;
$gta19: #a68467;
$gta20: #a39f41;
$gta21: #5081c8;
