#cardFilter .card-blue div:hover {
  background-color: #1361a2;
  border-radius: 8px;
  color: #ffff;
}

#cardFilter .card-blue:hover button {
  background: #ffff;
}

#cardFilter .card-blue:hover i {
  color: #1361a2;
}

#cardFilter:hover {
  cursor: pointer;
}

#cardFilter:hover #all h2 {
  color: #ffff;
}

#cardFilter:hover #all p {
  color: #ffff;
}

#cardFilter .card-green div:hover {
  background-color: #14a352;
  border-radius: 8px;
}

#cardFilter .card-green:hover button {
  background: #ffff;
}

#cardFilter:hover .card-green i {
  color: #14a352;
}

#cardFilter:hover #active h2 {
  color: #ffff;
}

#cardFilter:hover #active p {
  color: #ffff;
}

#cardFilter .card-red div:hover {
  background-color: #f0413e;
  border-radius: 8px;
}

#cardFilter:hover .card-red button {
  background: #ffff;
}

#cardFilter:hover .card-red i {
  color: #f0413e;
}

#cardFilter:hover #inactive h2 {
  color: #ffff;
}

#cardFilter:hover #inactive p {
  color: #ffff;
}

#cardFilter .card-yellow div:hover {
  background-color: rgb(255, 152, 0);
  border-radius: 8px;
}
#cardFilter:hover .card-yellow i {
  color: rgb(255, 152, 0);
}
#cardFilter:hover .card-yellow button {
  background: #ffff;
}

#cardFilter:hover #pending h2 {
  color: #ffff;
}

#cardFilter:hover #pending p {
  color: #ffff;
}

#cardFilter {
  border-radius: 8px;
  border: none;
}

#cardFilter button {
  border-radius: 50px;
  width: 55px;
  height: 55px;
}

#cardFilter .button-all button {
  background: #1361a2;
  border: none;
}

#cardFilter .button-active button {
  background: #14a352;
  border: none;
}

#cardFilter .button-inactive button {
  background: #f0413e;
  border: none;
}

#cardFilter .button-pending button {
  background: rgb(255, 152, 0);
  border: none;
}

#cardFilter #active h2 {
  margin-bottom: 0;
  color: #14a352;
}

#cardFilter #inactive h2 {
  color: #f0413e;
  margin-bottom: 0px;
}

#cardFilter #pending h2 {
  color: rgb(255, 152, 0);
  margin-bottom: 0px;
}

.card-body-filter {
  padding: 0.8rem;
  display: flex;
  justify-content: center;
}

#cardFilter #all,
#cardFilter #active,
#cardFilter #inactive,
#cardFilter #pending {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-left: 14px;
}

#cardFilter #all h2,
#cardFilter #active,
#cardFilter #inactive {
  text-align: left;
  margin-bottom: 0;
  color: #1361a2;
  display: flex;
  justify-content: center;
  align-items: center;
}

#cardFilter #all p,
#cardFilter #active p,
#cardFilter #inactive p,
#cardFilter #pending p {
  margin-bottom: -10px;
  text-align: left;
  color: #6b7c8f;
  display: flex;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
}

#cardFilter .button-all button i,
#cardFilter .button-active button i,
#cardFilter .button-inactive button i,
#cardFilter .button-pending button i {
  color: #ffff;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
