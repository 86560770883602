.notifications_table {
  // .table-hover tbody tr {
  background-color: #c9c9c9 !important;
  // }
  &:hover {
    background-color: #c9c9c9 !important;
  }
}

.notifications_table_read {
  // .table-hover tbody tr {
  background-color: white !important;
  &:hover {
    background-color: #fcfcfc !important;
  }
  // }
}
.notification-text-viewall{
  font-size: 14px;
  font-weight: 500 !important;
  padding: 16px 0px !important;
  color: #4e73df !important
}