.snackbar-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.snackbar-container {
  width: 736px;
  box-shadow: 7px 7px 18px -6px #1a273d42;  
  border: solid 1px #e0e7ff;
  background-color: #ffffff;
  padding: 1rem 2rem;
  margin: 0 0.5rem;
}

.error-container {
  border-left: 4px solid red;
}

.info-container {
  border-left: 4px solid #2b89e9;
}

.success-container {
  border-left: 4px solid #4cd16d;
}

.warning-container {
  border-left: 4px solid #ffbc22;
}

.snackbar-container > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.snackbar-info-container {
  display: flex;
  align-items: center;
}

.snackbar-info-container > div > h5:nth-child(1) {
  margin: 0 0 0.5rem 0;
}

.snackbar-info-container > div > h5:nth-child(2) {
  margin: 0;
}

.snackbar-container > div > div:nth-child(1) {
  margin-right: 1rem;
}

.snackbar-icon {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 1.3rem;
}
.muted-rubik-text-tr{
  border: none !important;
}
.muted-rubik-text-td{
  border: none;
  vertical-align: top;
  padding-right: 5px;
  height: auto;
  font-weight: bold;
  padding-bottom: 0px;
  line-height: 18px;
}
.snackbar-icon-alert {
  font-size: 34px; 
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 1.4rem;
}

.error-snackbar-icon {
  color: red;
}

.info-snackbar-icon {
  color: #2b89e9;
}

.success-snackbar-icon {
  color: #4cd16d;
}

.warning-snackbar-icon {
  color: #ffbc22;
}

.close-snackbar-icon {
  font-size: 22px; 
  font-weight: 100;
  cursor: pointer;
  margin-right: 4px;
  color: #767676ce;
}

#snackbar-fixed-container {
  position: fixed;
  top: 4rem;
  right: 12px;
}
.rubik-text {
  font-size: 17px;
  font-weight: 500;
  color: #2e384d;
}

.muted-rubik-text {
  font-size: 15px;
  color: #3e4044ce;
}

@media (max-width: 728px) {
  .snackbar-container {
    width: auto;
  }
}