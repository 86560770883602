.sidebar-dashboard .sidebar-brand-text,
.sidebar-dashboard a.collapse-item {
  font-size: 0.85rem !important;
}

.sidebar.toggled #catalogs-sidebar {
  top: -266px !important;
}

.collapse {
  margin: 0 !important;
}
i.nav-link:hover {
  color: #1e81c5;
}

.nav-item .collapse .collapsing {
  margin: 0 !important;
}
.bg-sidebar-open {
  background: #386496 !important;
  z-index: 1046;
  box-shadow: 6px 0px 18px -4px #0000003b;
}
.collapse-inner {
  padding: 0px !important;
}

a.collapse-item {
  color: #ffff !important;
  margin: 0 0.9rem !important;
}
a.collapse-item:hover {
  background-color: #40abea !important;
  padding: 0.5rem 1.5rem !important;
  margin: 0 !important;
  border-radius: 0 !important;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  #catalogs-sidebar {
    top: -350px !important;
  }
}

@media (min-width: 768px) {
  .sidebar.toggled .nav-item .nav-link span {
    display: none;
  }
  .sidebar-dark .sidebar-brand img {
    width: 80%;
    height: auto;
    max-width: 154px;
  }
}
