.goodpractices-card-body {
  width: 269px;
  display: inline-block;
  margin: 39px 39px 65px 0px;
  border-radius: 18px;
  background-color: #e5e5e5;
}

.join-footer {
  display: flex;
  padding: 10px;
  border-radius: 0 0 9px 9px;
}

.join-footer-left {
  float: left;
  padding: 6px 0px;
  width: 100px;
  color: white;
  text-transform: uppercase;
}
.join-footer-rigth {
  width: 330px;
  float: right;
  padding: 0px 6px 0px 6px;
  font-size: 11px;
  font-weight: 400;
  display: grid;
  align-items: center;
  justify-items: center;
  color: white;
  text-transform: uppercase;
}

.join__img-footer {
  height: auto;
  max-height: 289px;
  width: 100%;
  margin: 0px !important;
  padding: 0px 7px 0px 0px;
  z-index: 1;
}

.join-main {
  padding: 24px 14px 2px 14px;
  height: 20px;
  display: grid;
  align-items: center;
  justify-items: center;
  // display: table;
}

.join-name {
  font-size: 20px;
  line-height: 26px;
  padding: 0 5px;
  margin: 10px 0px 0;
  text-align: center;
  margin-top: -50px;
  font-weight: 400;
}

.join-header-front {
  display: flex;
  // display: flow-root;
  padding: 15px 10px 10px 15px;
}

.join-header-gta {
  float: left;
  width: 33px;
  height: auto;
}

.join-header-number {
  float: left;
  margin-right: 10px;
  margin-bottom: 4px;
  margin-top: 0px;
}

.join-header-gta-title {
  font-size: 12px;
  float: left;
  font-weight: 500;
  text-transform: uppercase;
  text-align: left;
}
.join-header-rigth-number {
  width: 59px;
  text-align: right;
  padding-right: 5px;
  font-weight: 400;
  font-size: 15px;
}
.join-filter-bar__label {
  color: white;
}

.join-inline-search {
  color: #fff;
  font-size: 1.5rem;
  margin: 2rem 4rem 0 -4px;
}
.join-label-select-register {
  // background-color: #f1f1f1;
  margin: 0 0 45px 0px;
  // width: 445px;
}
.join-user-name {
  color: #709ec9;
  font-weight: 600;
}

.sumate-table-label{
  font-weight: 700;
}