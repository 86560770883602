.spinner {
  top: 11rem;
  bottom: 0;
  left: 14rem;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
  z-index: 100;
  flex-direction: column;
  position: absolute;
  margin-left: 33px;
}

.loadingbar-style {
  background-color: '#7a9ec6';
  height: '5px';
  top: 0;
  left: 0;
  z-index: 999999;
}

.input-check-permits {
  padding-left: 13%;
  padding-bottom: 28px;
}

.spinner::-webkit-scrollbar {
  display: none;
}

.screen-logo {
  width: 400px;
  margin: 0 !important;
}

.spinner span {
  font-size: 2rem;
  animation: fade 1s linear 0s infinite;
  padding: 1rem;
}

.half-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #377ebc;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin 0.5s linear 0s infinite;
}

.completed {
  font-size: 2rem;
  color: #03fc4e;

  animation: bigger 1s linear;
}

@keyframes bigger {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(2);
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.panel {
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
  border-radius: 0;
  border: 0;
  margin-bottom: 24px;
  cursor: pointer;
}

.panel-margin {
  margin: 44px 0px;
}

.panel-fasfa-user-cog.panel-colorful {
  background-color: $gta15;
  border-color: $gta15;
  color: #fff;
  transition: all 0.1s ease;
  &:hover {
    background-color: lighten($gta15, 4);
    border-color: lighten($gta15, 50);
  }
}

.panel-fasfa-user.panel-colorful {
  background-color: $gta3;
  border-color: $gta3;
  color: #fff;
  transition: all 0.1s ease;
  &:hover {
    background-color: lighten($gta3, 2);
    border-color: lighten($gta3, 50);
  }
}

.panel-fasfa-question-circle {
  background-color: $gta19;
  border-color: $gta19;
  color: #fff;
  transition: all 0.1s ease;
  &:hover {
    background-color: lighten($gta19, 4);
    border-color: lighten($gta19, 50);
  }
}

.panel-fasfa-file {
  background-color: $gta14;
  border-color: $gta14;
  color: #fff;
  transition: all 0.1s ease;
  &:hover {
    background-color: lighten($gta14, 4);
    border-color: lighten($gta14, 50);
  }
}

.panel-fasfa-user-plus {
  background-color: $gta21;
  border-color: $gta21;
  color: #fff;
  transition: all 0.1s ease;
  &:hover {
    background-color: lighten($gta21, 4);
    border-color: lighten($gta21, 50);
  }
}

.panel-fasfa-star {
  background-color: $gta2;
  border-color: $gta2;
  color: #fff;
  transition: all 0.1s ease;
  &:hover {
    background-color: lighten($gta2, 4);
    border-color: lighten($gta2, 50);
  }
}

.panel-fasfa-list {
  background-color: $gta11;
  border-color: $gta11;
  color: #fff;
  transition: all 0.1s ease;
  &:hover {
    background-color: lighten($gta11, 4);
    border-color: lighten($gta11, 50);
  }
}

.panel-fasfa-fwfa-cog {
  background-color: $gta15;
  border-color: $gta15;
  color: #fff;
  transition: all 0.1s ease;
  &:hover {
    background-color: lighten($gta15, 4);
    border-color: lighten($gta15, 50);
  }
}

.panel-body {
  padding: 25px 20px;
}

.panel hr {
  border-color: rgba(0, 0, 0, 0.1);
}

.mar-btm {
  margin-bottom: 15px;
}

h2,
.h2 {
  font-size: 28px;
}

.small,
small {
  font-size: 85%;
}

.text-sm {
  font-size: 0.9em;
}

.text-thin {
  font-weight: 300;
}

.text-semibold {
  font-weight: 600;
}

legend.fieldset-gta {
  font-size: 1.4rem;
  margin-top: 18px;
  margin-bottom: 12px;
  // color: #2492d5;
}

ol.breadcrumb.breadcrumb_breadcrumb__OInSD {
  border-radius: 0px;
}

.gta-file-download{
  margin-top: 24px;
    padding-bottom: 15px;
    margin-bottom: -4px;
}

.icon-workaxis-table{
  height:55px; 
}
.div-icon-workaxis-table{
  justify-content: center;
  display: grid;
  width: 100%;
}

@font-face {
  font-family: "Flame"; 
  src: url('./../../assets/fonts/Flama.otf') format("opentype");
}

* {
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
}