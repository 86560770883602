.card-menu-icon {
  padding-top: 1.5rem;
  color: #dddddd !important;
  font-size: 2.5rem;
}
.fa-circle :hover {
  color: '#fff' !important;
}
.card-menu-icon :hover {
  color: '#fff' !important;
}

link {
  text-decoration: none;
}
.card-menu {
  background-color: #b3b3b3;
}
.card-menu :hover {
  background-color: #4d4d4d;
}
.card-menu-title {
  color: white !important;
  font-size: 1.5rem;
  padding-top: 7.5rem;
  padding-bottom: 1rem;
}
