@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// Base
@import './base/sb-admin-2';
@import './base/settings';
@import './base/base';

//Components
@import './src/components/Pages/pages';
@import './src/components/Modules/Cards/cards';
@import './src/components/Modules/Priorization/priorization';
@import './src/components/Theme/Container/container';
@import './src/components/Modules/Admins/admin';
@import './src/components/Theme/Layout/Header/notifications';
@import './src/components/Theme/Alerts/alerts';
@import './src/components/Modules/GoodPractices/goodPractice';