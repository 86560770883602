.otc legend {
  margin: 0 auto 1em;
  color: #5555ff;
}

.verification-input[type='text'] {
  width: 0.82em;
  line-height: 1;
  padding: 8px 0 4px;
  font-size: 1.75em;
  text-align: center;
  appearance: textfield;
  -webkit-appearance: textfield;
  border: 2px solid #bbbbff;
  color: purple;
  border-radius: 4px;
  text-transform:uppercase
}

.verification-center {
  justify-content: center;
}

.verification-input::-webkit-outer-spin-button,
.verification-inputinput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
