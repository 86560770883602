+ body {
  font-weight: 400;
  background-color: #f0f3f5;
  margin-top: 40px;
}

.row-layout-light {
  background-color: #fff;
  margin-right: -35px;
  margin-left: 0px;
}

.cards__row {
  // height: calc(100vh - 20px);
  height: 1400px;
  padding: 46px 0px 46px 56px;
  overflow: auto;
  background: transparent;
}

.custom-select-filter {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
}
.breadcrumb_breadcrumb__3uv0- {
  border-radius: 0px;
}

.custom-ranking {
  background: #bad4e9;
  border-radius: 8px;
  height: 37px;
  width: 90%;
  padding: 6px 35px;
  border-radius: 19px;
  background-color: #bad4e9;
  border-color: darken(#bad4e9, 8);
}

.filter-inline-search {
  color: #131a27;
  font-size: 1.5rem;
  margin: 2rem 4rem 0 0;
}

.search {
  width: 27px;
  height: 24.5px;
  color: #131a27;
  display: contents;
  cursor: pointer;
}

/*==============================*/
/*====== siderbar user profile =====*/
/*==============================*/
.nav > li > a.userdd {
  padding: 5px 15px;
}

.userprofile {
  width: 100%;
  float: left;
  clear: both;
  margin: 15px auto;
}

.userprofile .userpic {
  height: 100px;
  width: 100px;
  clear: both;
  margin: 0 auto;
  display: block;
  position: relative;
}

.userprofile .userpic .userpicimg {
  height: auto;
  width: 100%;
  //border-radius: 100%;
}

.username {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
  margin-top: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.descripname {
  font-weight: 400;
  font-size: 11px;
}

.username + p {
  color: #607d8b;
  font-size: 18px;
  font-weight: bold;
  line-height: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

h3.username {
  min-height: 60px;
}

.settingbtn {
  height: 30px;
  width: 30px;
  border-radius: 30px;
  display: block;
  position: absolute;
  bottom: 0px;
  right: 0px;
  line-height: 30px;
  vertical-align: middle;
  text-align: center;
  padding: 0;
  box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.15);
}

.userprofile.small {
  width: auto;
  clear: both;
  margin: 0px auto;
}

.userprofile.small .userpic {
  height: 40px;
  width: 40px;
  margin: 0 10px 0 0;
  display: block;
  border-radius: 100%;
  box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  float: left;
}

.userprofile.small .textcontainer {
  float: left;
  max-width: 100px;
  padding: 0;
}

.userprofile.small .userpic .userpicimg {
  min-height: 100%;
  width: 100%;
  border-radius: 100%;
}

.userprofile.small .username {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  margin: 0px;
  float: left;
  width: 100%;
}

.userprofile.small .username + p {
  color: #607d8b;
  font-size: 13px;
  float: left;
  width: 100%;
  margin: 0;
}

.socials {
  padding-bottom: 20px;
  min-width: 120px;
}

/*==============================*/
/*====== Social Profile css =====*/
/*==============================*/
.countlist h3 {
  margin: 0;
  font-weight: 400;
  line-height: 28px;
}

.countlist {
  text-transform: uppercase;
}

.countlist li {
  padding: 15px 30px 15px 0;
  font-size: 14px;
  text-align: left;
}

.countlist li small {
  font-size: 12px;
  margin: 0;
}

.followbtn {
  float: right;
  margin: 22px;
}

.userprofile.social {
  background: url(http://placehold.it/500x300) no-repeat top center;
  background-size: 100%;
  padding: 50px 0;
  margin: 0;
}

.userprofile.social .username {
  color: #ffffff;
}

.userprofile.social .username + p {
  color: #ffffff;
  opacity: 0.8;
}

.postbtn {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 9;
}

.status-upload {
  width: 100%;
  float: left;
  margin-bottom: 15px;
}

.posttimeline .panel {
  margin-bottom: 15px;
}

.commentsblock {
  background: #f8f9fb;
}

.nopaddingbtm {
  margin-bottom: 0;
}

/*==============================*/
/*====== Recently connected  heading =====*/
/*==============================*/
.memberblock {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 15px;
}

.member {
  width: 24%;
  float: left;
  margin: 2px 1% 2px 0;
  background: #ffffff;
  border: 1px solid #d8d0c3;
  padding: 3px;
  position: relative;
  overflow: hidden;
}

.memmbername {
  position: absolute;
  bottom: -30px;
  background: rgba(0, 0, 0, 0.8);
  color: #ffffff;
  line-height: 30px;
  padding: 0 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  font-size: 11px;
  transition: 0.5s ease all;
}

.member:hover .memmbername {
  bottom: 0;
}

.member img {
  width: 100%;
  transition: 0.5s ease all;
}

.member:hover img {
  opacity: 0.8;
  transform: scale(1.2);
}

.panel-default > .panel-heading {
  color: #607d8b;
  background-color: #ffffff;
  font-weight: 400;
  font-size: 15px;
  border-radius: 0;
  border-color: #e1eaef;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.428571429;
}

.page-header.small {
  position: relative;
  line-height: 22px;
  font-weight: 400;
  font-size: 20px;
}

.favorite i {
  color: #eb3147;
}

.btn i {
  font-size: 17px;
}

.panel {
  box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.05);
  transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  -webkit-transition: all ease 0.5s;
  -ms-transition: all ease 0.5s;
  margin-bottom: 35px;
  border-radius: 0px;
  position: relative;
  border: 0;
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  box-shadow: 6px 5px 14px 3px #0000002e;
  min-height: 285px;
}

.panel-footer {
  padding: 10px 15px;
  background-color: #ffffff;
  border-top: 1px solid #eef2f4;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  color: #607d8b;
}

.panel-blue {
  color: #ffffff;
  background-color: #03a9f4;
  cursor: pointer;

  &:hover {
    background-color: lighten($color: #03a9f4, $amount: 10);
  }
}

.panel-red.userlist .username,
.panel-green.userlist .username,
.panel-yellow.userlist .username,
.panel-blue.userlist .username {
  color: #ffffff;
}

.panel-red.userlist p,
.panel-green.userlist p,
.panel-yellow.userlist p,
.panel-blue.userlist p {
  color: rgba(255, 255, 255, 0.8);
}

.panel-red.userlist p a,
.panel-green.userlist p a,
.panel-yellow.userlist p a,
.panel-blue.userlist p a {
  color: rgba(255, 255, 255, 0.8);
}

.progress-bar-success,
.status.active,
.panel-green,
.panel-green > .panel-heading,
.btn-success,
.fc-event,
.badge.green,
.event_green {
  color: white;
  background-color: #8bc34a;
  cursor: pointer;

  &:hover {
    background-color: lighten($color: #8bc34a, $amount: 10);
  }
}

.userMaxWidth {
  max-width: 220px !important;
}

.progress-bar-warning,
.panel-yellow,
.status.pending,
.panel-yellow > .panel-heading,
.btn-warning,
.fc-unthemed .fc-today,
.badge.yellow,
.event_yellow {
  color: white;
  background-color: #ffc107;
  cursor: pointer;

  &:hover {
    background-color: lighten($color: #ffc107, $amount: 10);
  }
}

.progress-bar-danger,
.panel-red,
.status.inactive,
.panel-red > .panel-heading,
.btn-danger,
.badge.red,
.event_red {
  color: white;
  background-color: #dc3545;
  cursor: pointer;

  &:hover {
    background-color: lighten($color: #dc3545, $amount: 10);
  }
}

.btn-info {
  color: #000;
  background-color: #386496;
  border-color: #386496;

  &:hover {
    background-color: lighten(#386496, 15);
    border-color: lighten(#386496, 15);
  }
}

.userprofile .userpic {
  height: 93px;
  width: 100%;
  padding: 14px 14px 0px 14px;
  clear: both;
  margin: 0 auto;
  display: block;
  position: relative;
}

.auth__input {
  margin-top: 0rem !important;
}

/* entire container, keeps perspective */
.card-container {
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  -o-perspective: 800px;
  perspective: 800px;
  margin-bottom: 30px;
}

.btn-danger:hover {
  color: #fff;
  background-color: #e4606d;
  border-color: #e4606d;
}

/* flip the pane when hovered */
.card-container:not(.manual-flip):hover .card,
.card-container.hover.manual-flip .card {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.card-container.static:hover .card,
.card-container.static.hover .card {
  -webkit-transform: none;
  -moz-transform: none;
  -o-transform: none;
  transform: none;
}

/* flip speed goes here */
.card {
  -webkit-transition: -webkit-transform 0.5s;
  -moz-transition: -moz-transform 0.5s;
  -o-transition: -o-transform 0.5s;
  transition: transform 0.5s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
  cursor: pointer;
}

/* hide back of pane during swap */
.front,
.back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  border: 0.5px solid rgba(163, 163, 163, 0.356);
  box-shadow: 0px 1px 14px 5px rgba(0, 0, 0, 0.021);
}

/* front pane, placed above back */
.front {
  z-index: 2;
}

/* back, initially hidden pane */
.back {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  z-index: 3;
}

.back .btn-simple {
  position: absolute;
  left: 0;
  bottom: 4px;
}

.card {
  background: none repeat scroll 0 0 #ffffff;
  border-radius: 4px;
  color: #444444;
  border: 1px solid #f0f0f0e7 !important;
}

.card-container,
.front,
.back {
  width: 100%;
  height: 220px;
  border-radius: 4px;
  padding: 3px;
}

.card .cover {
  overflow: hidden;
  border-radius: 4px 4px 0 0;
}

.img-circle {
  position: relaitve;
  width: 100%;
  height: auto;
  z-index: 1;
}

.card .cover img {
  width: 100%;
}

// .card .user{
//     border-radius: 50%;
//     display: block;
//     height: 120px;
//     margin: -55px auto 0;
//     overflow: hidden;
//     width: 120px;
// }
// .card .user img{
//     background: none repeat scroll 0 0 #FFFFFF;
//     border: 4px solid #FFFFFF;
//     width: 100%;
// }

.card .content {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none;
  padding: 10px 20px 20px;
}

.card .content .mainCard {
  min-height: 160px;
  vertical-align: middle;
  padding: 5px;
}

.mainCard {
  padding: 8px;
}

.card .back .content .mainCard {
  height: 215px;
}

.card .name {
  font-size: 19px;
  line-height: 28px;
  padding: 0 8px;
  margin: 10px 0 0;
  text-align: center;
  margin-top: -23px;
  font-weight: 500;
}

.card h5 {
  margin: 5px 0;
  font-weight: 400;
  line-height: 20px;
}

.card .profession {
  color: #999999;
  text-align: center;
  margin-bottom: 20px;
}

.card .footer {
  border-top: 1px solid #f0f0f0;
  color: #999999;
  margin: 30px 0 0;
  padding: 10px 0 0;
  text-align: center;
}

.card .footer .social-links {
  font-size: 18px;
}

.card .footer .social-links a {
  margin: 0 7px;
}

.card .footer .btn-simple {
  margin-top: -6px;
}

.card .header {
  padding: 15px 20px;
  height: 90px;
}

.card .motto {
  border-bottom: 1px solid #eeeeee;
  color: #999999;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 10px;
  text-align: center;
}

.card .stats-container {
  width: 100%;
  margin-top: 50px;
}

.card .stats {
  display: block;
  float: left;
  width: 33.333333%;
  text-align: center;
}

.card .stats:first-child {
  border-right: 1px solid #eeeeee;
}

.card .stats:last-child {
  border-left: 1px solid #eeeeee;
}

.card .stats h4 {
  font-weight: 300;
  margin-bottom: 5px;
}

.card .stats p {
  color: #777777;
}

// /*      Just for presentation        */

.title {
  color: #506a85;
  text-align: center;
  font-weight: 300;
  font-size: 44px;
  margin-bottom: 90px;
  line-height: 90%;
}

.title small {
  font-size: 17px;
  color: #999;
  text-transform: uppercase;
  margin: 0;
}

.space-30 {
  height: 30px;
  display: block;
}

.space-50 {
  height: 50px;
  display: block;
}

.space-200 {
  height: 200px;
  display: block;
}

.white-board {
  background-color: #ffffff;
  min-height: 200px;
  padding: 60px 60px 20px;
}

.ct-heart {
  color: #f74933;
}

pre.prettyprint {
  background-color: #ffffff;
  border: 1px solid #999;
  margin-top: 20px;
  padding: 20px;
  text-align: left;
}

.atv,
.str {
  color: #05ae0e;
}

.tag,
.pln,
.kwd {
  color: #3472f7;
}

.atn {
  color: #2c93ff;
}

.pln {
  color: #333;
}

.com {
  color: #999;
}

// .btn-simple{
//     opacity: .8;
//     color: #666666;
//     background-color: transparent;
// }

// .btn-simple:hover,
// .btn-simple:focus{
//     background-color: transparent;
//     box-shadow: none;
//     opacity: 1;
// }
// .btn-simple i{
//     font-size: 16px;
// }

.navbar-brand-logo {
  padding: 0;
}

.navbar-brand-logo .logo {
  border: 1px solid #333333;
  border-radius: 50%;
  float: left;
  overflow: hidden;
  width: 60px;
}

.navbar .navbar-brand-logo .brand {
  color: #ffffff;
  float: left;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 10px;
  margin-top: 10px;
  width: 60px;
}

.navbar-default .navbar-brand-logo .brand {
  color: #555;
}

.containerCards {
  margin-left: -60px;
  margin-right: -60px;
}

.btn-circles {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}

.btn-circles.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
}

.btn-circles.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  font-size: 24px;
  line-height: 1.33;
  border-radius: 35px;
}

.btn-circle-link {
  border: 1px solid #ccc;
  color: white;
  width: 200px;
  height: 200px;
  border-radius: 120px;
  font-size: 21px;
  font-weight: 500;
  box-shadow: 4px 4px 13px -10px black;
  transition: background 0.2s ease;
}

.btn-circle-blue {
  border: 4px solid lighten(#4498d9, 5%);
  background: lighten(#4498d9, 5%);
  color: #fff;

  &:hover {
    background: transparent;
    color: #4498d9;
  }
}

.btn-circle-yellow {
  border: 4px solid lighten(#f29c25, 5%);
  background: lighten(#f29c25, 5%);
  color: #fff;

  &:hover {
    background: transparent;
    color: #f29c25;
  }
}

.btn-circle-green {
  background: lighten(#4cac24, 5%);
  color: #fff;
  border: 4px solid lighten(#4cac24, 5%);

  &:hover {
    background: transparent;
    color: #4cac24;
  }
}

.row-circles {
  max-width: 700px;
  margin: 0 0;
  display: inline-flex !important;
}

// /*       Fix bug for IE      */

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .front,
  .back {
    -ms-backface-visibility: visible;
    backface-visibility: visible;
  }

  .back {
    visibility: hidden;
    -ms-transition: all 0.2s cubic-bezier(0.92, 0.01, 0.83, 0.67);
  }

  .front {
    z-index: 4;
  }

  .card-container:not(.manual-flip):hover .back,
  .card-container.manual-flip.hover .back {
    z-index: 5;
    visibility: visible;
  }
}

// Flashcards


/* entire container, keeps perspective */
.flashcard-container {
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  -o-perspective: 800px;
  perspective: 800px;
  margin-bottom: 30px;
}
/* flip the pane when hovered */
.flashcard-container:not(.manual-flip):hover .flashcard,
.flashcard-container.hover.manual-flip .flashcard {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.flashcard-container.static:hover .flashcard,
.flashcard-container.static.hover .flashcard {
  -webkit-transform: none;
  -moz-transform: none;
  -o-transform: none;
  transform: none;
}

/* flip speed goes here */
.flashcard {
  -webkit-transition: -webkit-transform 0.5s;
  -moz-transition: -moz-transform 0.5s;
  -o-transition: -o-transform 0.5s;
  transition: transform 0.5s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
  cursor: pointer;
}

/* hide back of pane during swap */
.flashcard-front,
.flashcard-back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  // border: 0.5px solid #cccccc63;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #eaeaea;
}

.flashcard-back {
  box-shadow: 18px 16px 20px -15px #8c8c8c0f;
  border: 0.5px solid #ccccccb0;
}

/* front pane, placed above back */
.flashcard-front {
  z-index: 2;
  box-shadow: 16px 16px 8px -7px #8c8c8c0f;
}

/* back, initially hidden pane */
.flashcard-back {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  z-index: 3;
  color: #666666;
}

.flashcard-back .btn-simple {
  position: absolute;
  left: 0;
  bottom: 4px;
}

.flashcard-card {
  background: none repeat scroll 0 0 #ffffff;
  border-radius: 8px;
  color: #444444;
  border: 1px solid #f0f0f0e7 !important;
}

.flashcard-container,
.flashcard-front,
.flashcard-back {
  width: 100%;
  height: 365px;
  border-radius: 8px;
  min-width: 235px;
  margin-bottom: 68px;
}

.flashcard .flashcard-cover {
  overflow: hidden;
  border-radius: 8px;
}

.flashcard-img-circle {
  position: relaitve;
  width: 100%;
  height: auto;
  z-index: 1;
}

.flashcard .flashcard-cover img {
  width: 100%;
}

.flashcard .flashcard-content {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none;
  padding: 10px 20px 20px;
}

.flashcard .flashcard-content .flashcard-main {
  min-height: 160px;
  vertical-align: middle;
  padding: 5px;
}

.flashcard-main {
  padding: 15px 10px 2px 14px;
  height: 123px;
  display: grid;
  display: grid;
  align-items: center;
  justify-items: center;
}

.flashcard .flashcard-back .flashcard-content .flashcard-main {
  height: 215px;
}

.flashcard .flashcard-name {
  font-size: 16px;
  line-height: 21px;
  padding: 0 5px;
  text-align: center;
  justify-content: center;
  font-weight: 300;
  justify-items: center;
  display: grid;
  overflow: hidden;
  max-height: 105px;
}

.flashcard-name {
  // display: table-cell;
  vertical-align: middle;
}

.flashcard h5 {
  margin: 5px 0;
  font-weight: 400;
  line-height: 20px;
  margin: 10px 0px -4px 15px !important;
}
.flashcard .flashcard-profession {
  color: #999999;
  text-align: center;
  margin-bottom: 20px;
}
.flashcard .flashcard-footer {
  border-top: 1px solid #f0f0f0;
  color: #999999;
  margin: 30px 0 0;
  padding: 10px 0 0;
  text-align: center;
}
.flashcard .flashcard-footer .flashcard-social-links {
  font-size: 18px;
}
.flashcard .flashcard-footer .flashcard-social-links a {
  margin: 0 7px;
}

.flashcard .flashcard-footer .btn-simple {
  margin-top: -6px;
}
.flashcard .flashcard-header {
  padding: 15px 20px;
  height: 90px;
}
.flashcard .flashcard-motto {
  border-bottom: 1px solid #eeeeee;
  color: #999999;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 10px;
  text-align: center;
}

.flashcard .flashcard-stats-container {
  width: 100%;
  margin-top: 50px;
}

.flashcard-front-inner {
  height: 100%;
  border-radius: 8px;
  padding: 0px 5px;
}

.flashcard .flashcard-stats {
  display: block;
  float: left;
  width: 33.333333%;
  text-align: center;
}

.flashcard .flashcard-stats:first-child {
  border-right: 1px solid #eeeeee;
}
.flashcard .flashcard-stats:last-child {
  border-left: 1px solid #eeeeee;
}
.flashcard .flashcard-stats h4 {
  font-weight: 300;
  margin-bottom: 5px;
}
.flashcard .flashcard-stats p {
  color: #777777;
}

.flashcard-header-front,
.flashcard-footer-front-grupo,
.flashcard-footer-front-eje {
  color: white;
  font-weight: 400;
}

.flashcard-footer-front-grupo,
.flashcard-footer-front-eje {
  text-align: center;
}

.flashcard-footer-front-grupo {
  font-size: 11.5px;
  height: 18px;
  line-height: 11px;
  padding: 0 15px;
}

.flashcard-div-image-eje {
  display: block;
}

.flashcard-img-eje {
  height: 70px;
  display: block;
  margin: auto;
  margin-top: -34px;
  margin-bottom: -9px;
  margin-left: 77px !important;

  @media screen and (max-width: 991px) {
    margin-left: 64px !important;
  }
}

.flashcard-header-gta {
  float: left;
  margin-left: -13px;
}

.flashcard-header-number {
  float: right;
  margin-right: -2px;
}

.flashcard-header-front {
  padding: 18px 15px 10px 12px;
  margin: 0px 16px;
  text-align: right;
  display: flow-root;
  border-bottom: 0.5px solid #ffffff91;
}

.flashcard-climb {
  text-align: left;
}

.flashcard-text-center {
  text-align: left;
  padding-bottom: 7px;
  margin: 8px 8px !important;
  // border-bottom: 0.5px solid #9a9a9a91;
}

.flashcard-div-border {
  background: white;
  padding: 7px 7px 7px 7px;
  margin: 11px;
  border-radius: 7px;
  box-shadow: 1px 2px 13px -10px #0000008a;
}

.flashcard-text-solution {
  padding: 8px 15px 1px 15px;
  height: 230px;
  overflow: hidden;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 9px;
}

.flashcard-footer-back-indicador {
  width: 100%;
  font-weight: 300;
  text-align: right;
  padding-right: 6px;
  font-size: 15px;
}

.flashcard-footer-back-indicadordetalle {
  text-align: center;
  line-height: 15px;
  height: 30px;
}

.flashcard-footer-back-climb {
  margin-top: -3px;
  width: 93px;
  justify-content: center;
  display: flex;
}

.flashcard-footer-back-climb2 {
  margin-top: 0px;
  width: 93px;
  justify-content: center;
  display: flex;
}
.card-detail-content-front-s {
  width: 265px;
  height: 364px;
  color: white;
  border-radius: 10px;
  padding: 18px 21px 0px 21px;
  margin-right: 15px;
  margin-bottom: 20px;
  flex-direction: column;
  display: flex;
}
.flashcard-detail-row-s {
  margin: 0px;
}
.flashcard-detail-front-header-s {
  width: 20px;
  display: block;
}
.flashcard-detail-front-icon-s {
  height: 43px;
  width: auto;
  margin: 0px 18px 4px -20px !important;
}
.flashcard-detail-front-title-s {
  width: 185px;
  padding: 0px;
  margin-left: 5px;
  display: grid;
  max-width: 301px;
  margin-bottom: 12px;
}
span.flashcard-detail-front-gta-s {
  font-size: 11px;
  margin-top: 6px;
  margin-bottom: 4px;
  margin-left: 8px;
  font-weight: 800;
}
span.flashcard-detail-front-gta2-s {
  margin-left: 8px;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  margin-top: -6px;
  overflow: hidden;
}
.flashcard-detail-front-div-solution {
  text-align: center;
  font-size: 21px;
  line-height: 21px; 
  padding: 0px 0 3px 0 ; 
  display: table;
}
.flashcard-detail-front-line {
  width: 100%;
  margin: 2.5px 0px 7.5px -3px;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.555);
}
.flashcard-detail-front-solution-s {
  display: table-cell;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
}
.flashcard-detail-div-image-eje-s {
  display: table;
  padding: 0px 0 0px 0;
}
.flashcard-detail-div-ambito-front-title-s{
  text-align: center;  
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 1px; 
}
.flashcard-list-ul-front-s {
  margin-top: 0px;
  margin-bottom: 8px;
}
.flashcard-list-li-front-s{
  line-height: 9px;
  font-size: 9px;
}
.flashcard-detail-front-footer-s {
  width: 124px;
  padding: 0px;
  display: contents;
}
.flashcard-front-div-eje-center-s{
  display: flex; 
  margin: -5px auto -5px auto;  
  padding-right: 24px;
  padding-bottom: 5px;
}
.flashcard-detail-img-eje-s {
  height: 39px;
  width: auto;
  display: table-cell; 
  justify-content: center;
}
.flashcard-detail-footer-front-grupo-s {
  font-size: 13px;
  text-align: center; 
  align-items: center;
  display: flex;
  margin-bottom: 2px;
}
.flashcard-detail-front-footer-left-s {
  width: 135px;
    text-align: left;
    font-size: 10px;
    margin: 10px 0px 8px 0px;
    overflow: hidden;
    display: block;
    font-weight: 500;
}
.flashcard-list-li-front-s{
  line-height: 9px;
  font-size: 9px;
}
.flashcard-detail-front-footer-number-right-s{
  float: right;
  margin: 6px 0px 8px 24px;
  width: 36px;
  font-size: 13px;
  text-align: right;
}
.flashcard-footer-back-tiempo {
  width: 87px;
  padding-top: 3px;
  margin-right: 10px;
  display: block;
  justify-content: center;
  overflow: hidden;
  font-size: 13px;
  text-align: right;
  font-weight: 500;
}
.flashcard-footer-back {
  display: flex;
  padding-top: 5px;
}

.flashcard-letra-climb {
  background: #4babd3;
  color: white;
  font-size: 14px;
  font-weight: 500;
  width: 30px;
  height: 30px;
  margin-right: 1px;
  padding: 1px 14px 0 5px;
  border-radius: 100%;
  box-shadow: 3px 4px 6px -2px #cccccca3;
}
.flashcard-climb-text-C,
.flashcard-climb-text-L {
  color: white;
  margin: 2px 13px 0 -21px;
  font-size: 18px;
  font-weight: 400;
  width: 23px;
}

.flashcard-climb-text-small-C {
  color: white;
  margin: 2px 8px 0 -16px;
  font-size: 14px;
  font-weight: 500;
  width: 23px;
}
.flashcard-climb-text-small-L {
  color: white;
  margin: 2px 8px 0 -14px;
  font-size: 14px;
  font-weight: 500;
  width: 23px;
}

.flashcard-climb-text-small-I {
  color: white;
  margin: -1px 11px 0 -13px;
  font-size: 18px;
  font-weight: 400;
  width: 23px;
}

.flashcard-climb-text-small-M {
  color: white;
  margin: 0px 7px 0 -17px;
  font-size: 15px;
  font-weight: 400;
  width: 23px;
}

.flashcard-climb-text-small-B {
  color: white;
  margin: -1px 15px 0 -16px;
  font-size: 17px;
  font-weight: 400;
  width: 23px;
}

.flashcard-climb-text-I {
  color: white;
  margin: 2px 15px 0 -18px;
  font-size: 18px;
  font-weight: 400;
  width: 23px;
}

.flashcard-climb-text-M {
  color: white;
  margin: 2px 11px 0 -23px;
  font-size: 18px;
  font-weight: 400;
  width: 23px;
}

.flashcard-climb-text-B {
  color: white;
  margin: 2px 13px 0 -20px;
  font-size: 18px;
  font-weight: 400;
  width: 23px;
}

.flashcard-letra-climb-l {
  padding-left: 7px;
  margin-right: 2px;
}

.flashcard-letra-climb-i {
  padding-left: 8px;
  padding-right: 13px;
  margin-right: 2px;
}

.flashcard-letra-climb-m {
  padding-left: 4px;
}

.flashcard-letra-climb-b {
  padding-left: 6px;
}

.flashcard-header-gta-name,
.flashcard-header-gta-title {
  font-size: 11px;
  font-weight: 500 !important;
  text-align: left;
}

.flashcard-header-gta-title {
  font-size: 11px;
  font-weight: 400;
}

.flashcard-header-gta-name2,
.flashcard-header-gta-title2 {
  font-size: 11px;
  font-weight: 400;
  text-align: left;
  height: 16px;
  width: 168px;
  line-height: 12px;
}

.flashcard-header-gta-title2 {
  font-size: 8px;
  font-weight: 400;
}

.flashcard-header-gta {
  width: 36px;
}

.flashcard-header-gta-img {
  width: 49px;
  height: auto;
  position: absolute;
  margin: -2px 0px 0 0px;
  margin-left: -45px !important;
}
.flashcard-header-number {
  float: right;
  margin-bottom: 4px;
  margin-top: 7px;

  @media (max-width: 991px) {
    margin-right: -29px;
  }
}
.flashcard-footer-front-eje {
  border-top: 0.5px solid #ffffff91;
  margin: 10px 15px -4px 15px;
  font-weight: 500;
  height: 32px;
}

.flashcard-footer-left {
  float: left;
  font-size: 10px;
  font-weight: 400;
  padding: 6px 0px;
  text-transform: uppercase;
}

.flashcard-footer-rigth {
  width: 100%;
  font-size: 12px;
  text-align: center;
  padding-top: 5px;
  font-weight: 400;
  overflow: hidden;
  height: 20px;
}
.flashcard-footer-rigth-number {
  float: right;
  padding: 4px 0px;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
}
// Slider

.cards__div-slider {
  margin-left: -5px;
  margin-right: -5 px;
}

.cards__slider-row {
  height: 100%;
  padding: 5px 100px;
  overflow: auto;
  background: transparent;
}

.cards__card-dimension {
  // max-width: 260px !important;
  min-width: 250px;
  padding-top: 10px;
}

.flashcard-footer-front-eje-2 {
  padding-top: 15px;
}

.flashcard-footer-front-eje-3 {
  padding-top: 5px;
  padding-bottom: 5px;
}
// Card Detail

.card__card_left,
.card__card_right {
  width: 200px;
  height: 320px;
  border-radius: 8px;
  box-shadow: 3px 3px 9px -4px #cccccc96;
}

.card__card_left {
  margin: 20px 5px 20px auto;
  border: 0.5px solid #ac3ada26;
  background: #ac3ada26;
}

.card__card_right {
  margin: 20px auto 20px 5px;
  border: 0.5px solid #ccc;
  background: #e6e6e626;
}

.card__detail-gta {
  margin: auto;
  padding: auto;
  padding-top: 19px;
}


// Chat - messages

.row.row-broken {
  padding-bottom: 0;
}

.col-inside-lg {
  padding: 20px;
}

.chat {
  height: calc(100vh - 180px);
}

.decor-default {
  background-color: #ffffff;
}

.chat-users h6 {
  font-size: 20px;
  margin: 0 0 20px;
}

.chat-users .user {
  position: relative;
  padding: 0 0 0 50px;
  display: block;
  cursor: pointer;
  margin: 0 0 20px;
}

.chat-users .user .avatar {
  top: 0;
  left: 0;
}

.chat .avatar {
  width: 40px;
  height: 40px;
  position: absolute;
}

.chat .avatar img {
  display: block;
  border-radius: 20px;
  height: 100%;
}

.chat .avatar .status.off {
  border: 1px solid #5a5a5a;
  background: #ffffff;
}

.chat .avatar .status.online {
  background: #4caf50;
}

.chat .avatar .status.busy {
  background: #ffc107;
}

.chat .avatar .status.offline {
  background: #ed4e6e;
}

.chat-users .user .status {
  bottom: 0;
  left: 28px;
}

.chat .avatar .status {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  position: absolute;
}

.chat-users .user .card__name-avatar {
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-users .user .mood {
  font: 200 14px/20px 'Raleway', sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card__send-message {
  width: 100px;
}

/*****************CHAT BODY *******************/
.chat-body h6 {
  font-size: 20px;
  margin: 0 0 20px;
}

.chat-body .answer.left {
  padding: 0 0 0 58px;
  text-align: left;
  float: left;
}

.chat-body .answer {
  position: relative;
  max-width: 600px;
  overflow: hidden;
  clear: both;
}

.chat-body .answer.left .avatar {
  left: 0;
}

.chat-body .answer .avatar {
  bottom: 36px;
}

.chat .avatar {
  width: 40px;
  height: 40px;
  position: absolute;
}

.chat .avatar img {
  display: block;
  border-radius: 20px;
  height: 100%;
}

.chat-body .answer .name {
  font-size: 14px;
  line-height: 36px;
}

.chat-body .answer.left .avatar .status {
  right: 4px;
}

.chat-body .answer .avatar .status {
  bottom: 0;
}

.chat-body .answer.left .text {
  background: #ebebeb;
  color: #333333;
  border-radius: 8px 8px 8px 0;
}

.chat-body .answer .text {
  padding: 12px;
  font-size: 16px;
  line-height: 26px;
  position: relative;
}

.chat-body .answer.left .text:before {
  left: -30px;
  border-right-color: #ebebeb;
  border-right-width: 12px;
}

.chat-body .answer .text:before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  border: 18px solid transparent;
  border-bottom-width: 0;
}

.chat-body .answer.left .time {
  padding-left: 12px;
  color: #333333;
}

.chat-body .answer .time {
  font-size: 16px;
  line-height: 36px;
  position: relative;
  padding-bottom: 1px;
}

/*RIGHT*/
.chat-body .answer.right {
  padding: 0 58px 0 0;
  text-align: right;
  float: right;
}

.chat-body .answer.right .avatar {
  right: 0;
}

.chat-body .answer.right .avatar .status {
  left: 4px;
}

.chat-body .answer.right .text {
  background: #7266ba;
  color: #ffffff;
  border-radius: 8px 8px 0 8px;
}

.chat-body .answer.right .text:before {
  right: -30px;
  border-left-color: #7266ba;
  border-left-width: 12px;
}

.chat-body .answer.right .time {
  padding-right: 12px;
  color: #333333;
}

/**************ADD FORM ***************/
.chat-body .answer-add {
  clear: both;
  position: relative;
  margin: 20px -20px -20px;
  padding: 20px;
  background: #46be8a;
}

.chat-body .answer-add input {
  border: none;
  background: none;
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  padding: 0;
  color: #ffffff;
}

.chat input {
  -webkit-appearance: none;
  border-radius: 0;
}

.chat-body .answer-add .answer-btn-1 {
  background: url('http://91.234.35.26/iwiki-admin/v1.0.0/admin/img/icon-40.png') 50% 50%
    no-repeat;
  right: 56px;
}

.chat-body .answer-add .answer-btn {
  display: block;
  cursor: pointer;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 50%;
  margin-top: -18px;
}

.chat-body .answer-add .answer-btn-2 {
  background: url('http://91.234.35.26/iwiki-admin/v1.0.0/admin/img/icon-41.png') 50% 50%
    no-repeat;
  right: 20px;
}

.chat input::-webkit-input-placeholder {
  color: #fff;
}

.chat input:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}

.card__avatar {
  width: auto;
  height: 40px;
}

.khvUfi {
  color: #fff !important;
  background-color: #709ec9bf;
}

.khvUfi:hover:enabled,
.khvUfi:focus:enabled {
  background-color: $secundario3 !important;
  box-shadow: 0 0 2px 0 #333;
}

.jJKuoL {
  background-color: $secundario3 !important;
  box-shadow: 0 0 1px 3px $secundario3 !important;
}

.gQIqMI {
  background-color: #ffffff61 !important;
  box-shadow: 0 0 1px 2px #ffffff61 !important;
}

// Detalle de la tarjeta
.Contratar-externosas-para-evaluar-los-procesos-de-reclutamiento-con-perspectiva-de-gnero {
  width: 740px;
  height: 74px;
  font-size: 35px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #e5402b;

  @media screen and (max-width: 768px) {
    width: 90%;
    height: auto;
    font-size: 25px;
    padding: 0 20px 0 0;
    margin: 0 0 0 0;
  }  
  
}

.Rectngulo-141 {
  width: 759px;
  padding: 32px 36px 51px 35px;
  background-color: #fff;
}

.card__detail-container {
  margin-top: 60px;
}

.card-detail-number {
  width: 100%;
  height: 21px;
  display: inline-block;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #131a27;
}

.Trazado-29 {
  width: 57.5px;
  height: 57.5px;
  padding: 5.6px 4.2px 35px 2.4px;
  background-color: #e5402b;
}

.flashcard-img-detail-gta {
  height: 123px;
  width: auto;
  display: block;
  margin: auto;
  margin-top: -34px;
  margin-left: -10px !important;
}

.Diversidad-e-Inclusin-Igualdad-de-gnero {
  width: 243px;
  font-size: 18px;
  display: block;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e5402b;
}

.Diversidad-e-Inclusin-Igualdad-de-gnero .text-style-1 {
  display: block;
  font-weight: 500;
}

.Lnea-148 {
  width: 210px;
  height: 1px;
  margin: 17.5px 4px 7.5px 0px;
  background-color: #e5402b;
}

.Participacin-econmica-de-la-mujer {
  width: 152px;
  height: 40px;
  margin: 7.5px 47px 27px 0px;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e5402b;
}

.Rectngulo-420 {
  width: 194px;
  height: 27px;
  margin: 0px 26.1px 7px 0px;
  padding: 2px 8.1px 5px 8.8px;
  border-radius: 14px;
  background-color: #fff;
  border: solid 1px #7e7e7e;
}

.Priorizacin {
  width: 80px;
  height: 19px;
  margin: 0 4.8px 0 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.Rectngulo-421 {
  width: 142.7px;
  height: 27px;
  margin: 7px 61.1px 37px 0px;
  padding: 2px 10.1px 2px 8.8px;
  border-radius: 14px;
  border: solid 1px #7e7e7e;
}

.Implementacin {
  width: 112px;
  height: 19px;
  margin: 0 11.8px 0 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4d4d4d;
}

.Elipse-152 {
  width: 12px;
  height: 12px;
  margin: -19px 0 5px 111.8px;
  background-color: #7cd07c;
  border-radius: 100%;
}

.Ver-mis-tarjetas-guardadas {
  width: 163px;
  height: 16px;
  margin: 43.5px 5.5px 22.5px 4.9px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4d4d4d;
}

.flashcard-detail-image {
  height: 400px;
  width: auto;
  margin-right: -52px;
  border-radius: 8px !important;
}

.card__detail-container {
  min-height: 120vh;
}

.Guardar-tarjeta {
  width: 65px;
  height: 42px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  display: block;
  margin-left: -15px;
  margin-top: 5px;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.card-detail-bookmark {
  margin: 156px 0 20px -20px;
}

.card-detail-actions {
  width: 96px;
  display: inline-flex;
  padding: 20px;
  margin: 0px;
  vertical-align: middle;
  float: left;
  height: 300px;
  padding-top: 215px;
}

.Rectngulo-406 {
  width: 807px;
  // height: 111px;
  margin: -6px 16px 44px 1px;
  padding: 17px 26px 20px 22px;
  border-radius: 9px;
  background-color: #4d4d4d;
}

textarea#comment {
  margin-top: -45px;
  width: 647px;
  height: 70px;
}

.title-comments {
  margin: -34px 0px 22px 30px !important;
  font-size: 23px !important;
}

.coomentbox-image-author2 {
  border-radius: 50%;
  width: 60px;
  border: 0.5px solid #ffffff2b;
  box-shadow: 4px 5px 11px -5px #00000085;
  background-color: white;
  height: 60px;
  margin: -43px 12px 0 7px !important;
}

.coomentbox-image-author3 {
  border-radius: 50%;
  width: 50px;
  border: 0.5px solid #ffffff2b;
  box-shadow: 4px 5px 11px -5px #00000085;
  background-color: white;
  height: 50px;
  margin: 6px 12px 0 7px !important;
}

.coomentbox-image-author {
  border-radius: 50%;
  width: 50px;
  height: auto;
}

.btn-commentary {
  border: 0.5px solid #fff;
  background-color: transparent;
  float: right;
  display: block;
  border-radius: 8px;
  margin-right: 2px;
  color: white;
  transition: all 0.5s ease;

  &:hover {
    background-color: #fff;
    color: #4d4d4d;
  }
}

.Like {
  width: 223px;
  height: 21px;
  margin: 0 24.6px 9px 0;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.IMG_4E9C8D1823B7-1 {
  width: 44px;
  height: 44px;
  margin: 9px 0 0;
  display: contents;
}

.Escribe-aqu-tu-comentario {
  width: 223px;
  height: 16px;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.Screen-Shot-2021-06-17-at-190148 {
  width: 44px;
  height: 44px;
  margin: 5px 10px 7px 0;
}

.Rectngulo-408 {
  width: 406px;
  height: 49px;
  margin: 0 0 7px 10px;
  padding: 5px 168px 9px 15px;
  border-radius: 14px;
  background-color: #fff;
}

.Me-fue-muy-til-esta-tarjeta {
  width: 223px;
  height: 16px;
  margin: 3px 0 0;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #151515;
}

.flashcard-detail-div-ambito-front-title{
  text-align: center;  
  font-weight: 600;
  margin-bottom: 5px;
}

.card-detail-content-front {
  width: 395px;
  height: 578px;
  color: white;
  border-radius: 10px;
  padding: 18px 25px 0px 25px;
  margin-right: 15px;
  margin-bottom: 20px;
  flex-direction: column;
  display: flex;
}
.card-detail-content-back {
  width: 395px;
  height: 578px;
  border-radius: 10px;
  padding: 30px 15px;
  margin-right: 15px;
  margin-bottom: 20px;
  background: #fff;
  box-shadow: 0 0.05rem 0.01rem rgb(75 75 90 / 8%);
}
.flashcard-detail-back-colortext{
  font-weight: 600;
  font-size: 17px;
  padding: 0 0 10px 0;
  line-height: 35px;
}

.flashcard-detail-front-icon {
  height: 53px;
  width: auto;
  margin: 0px 18px 4px -20px !important;
}

.flashcard-detail-row {
  margin: 0px;
}

.flashcard-detail-front-header {
  width: 40px;
  display: block;
}

.flashcard-detail-front-title {
  width: 304px;
  padding: 0px;
  display: grid;
  margin-bottom: 12px;
}

span.flashcard-detail-front-gta {
  font-size: 16px;
  margin-top: 7px;
  margin-bottom: 3px;
  margin-left: 8px;
  font-weight: 600;
}

span.flashcard-detail-front-gta2 {
  margin-left: 8px;
  font-size: 13px;
  margin-top: -7px;
  overflow: auto;
}

.coomentbox-comment {
  border-radius: 8px;
}

.flashcard-detail-front-line {
  width: 100%;
  margin: 2.5px 0px 7.5px -3px;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.555);
}

.flashcard-detail-front-div-solution {
  text-align: center;
  font-size: 21px;
  line-height: 21px; 
  padding: 10px 0 14px 0 ; 
  display: table;
}

.flashcard-detail-front-solution {
  display: table-cell;
  vertical-align: middle;
}

.flashcard-detail-front-footer {
  width: 224px;
  padding: 0px;
  display: contents;
}

.flashcard-detail-front-footer-left {
    width: 136px;
    text-align: left;
    font-size: 14px;
    margin: 15px auto 8px 0px;
    overflow: hidden;
    display: block;
    font-weight: 500;
  }
  .flashcard-detail-front-footer-number-right{
    float: right;
    margin: 15px 0px 8px 0px;
    width: 62px;
    text-align: right;
}
.flashcard-detail-front-footer-right {
  width: 100%;
  display: block;
  font-size: 14px;
  margin-top: 14px;
  text-align: center;
  margin-bottom: 15px;
}

.flashcard-detail-footer-front-grupo {
  font-size: 17px;
  text-align: center; 
  align-items: center;
  display: flex;
  margin-bottom: 7px;
}
.flashcard-front-div-eje-center{
  display: flex; 
  margin: auto;
  padding-right: 24px;
}

.flashcard-detail-div-image-eje {
  display: table;
  padding: 5px 0 10px 0;
}

.flashcard-list-li-front{
  line-height: 14px;
  font-size: 14px;
}

.flashcard-detail-img-eje {
  height: 59px;
  width: auto;
  display: table-cell; 
  justify-content: center;
}

.flashcard-detail-back-div {
  background: #fff;
  border-radius: 4px;
  margin: 0 0 6px 0;
  padding: 0px 9px;
  overflow: hidden;
}
.flashcard-detail-back-div2 {
  background: #f8f8f8;
  border-radius: 4px;
  margin: 0 0 6px 0;
  padding: 6px 9px 10px 18px;
  overflow: auto;
  height: 496px;
}

.flashcard-detail-back-div-solution {
  height: 530px;
  overflow: auto;
}

.vertical-timeline-element-icon svg {
  margin-left: -12px !important;
}

.flashcard-detail-back-text {
  font-size: 14px;
  color: #3c3c3c;
}

.flashcard-content-card {
  max-width: 258px;
  min-width: 250px;
}

.flashcard-detail-back-text-ownership {
  margin-bottom: 1px;
}
.flashcard-name2 {
  color: #fff;
}
.Rectngulo-1411 {
  margin: 233px 0 0 -45px;
  // position: absolute;
}

.Rectngulo-1412 {
  margin: 233px 0 0 -45px;
  position: absolute;
}

.card {
  margin: 0px 28px 27px 28px;
}

.card-body {
  flex: 1 1 auto;
}

.task-container {
  overflow: auto;
  margin-bottom: 18px;
  background: rgba(204, 204, 204, 0.37);
  padding: 5px;
  max-width: 79vw;
  height: 376px;
  border: 0.5px solid #c9c9c9;
}

.carousel-date-inputs {
  width: 200px;
  display: inline-block;
}

.flashcard-name2 {
  font-size: 17px;
  line-height: 28px;
  padding: 0 5px;
  margin: 10px 0 0;
  text-align: center;
  margin-top: -29px;
  font-weight: 400;
}

.flashcard-front2 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #eaeaea;
}

.flashcard-front2 {
  z-index: 2;
  box-shadow: 3px 4px 7px 0px #30303029;
}

.flashcard-front2 {
  width: 100%;
  height: 324px;
  border-radius: 8px;
  min-width: 235px;
}

// .card .card-header.no-border {
//     border: 0;
// }
// .card .card-header {
//     background: none;
//     padding: 0 0.9375rem;
//     font-weight: 500;
//     display: flex;
//     align-items: center;
//     min-height: 50px;
// }
// .card-header:first-child {
//     border-radius: calc(8px - 1px) calc(8px - 1px) 0 0;
// }

.vertical-timeline-element-content.bounce-in {
  padding: 20px 0px 0px 0px;
  margin: 0px;
  border: 0px;
}

.widget-49 {
  border: 0px;
}

.rounded-x {
  border-radius: 50% !important;
}

.widget-49 .widget-49-title-wrapper {
  display: flex;
  align-items: center;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #edf1fc;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-primary .widget-49-date-day {
  color: #4e73e5;
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-primary .widget-49-date-month {
  color: #4e73e5;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fcfcfd;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-secondary .widget-49-date-day {
  color: #dde1e9;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-secondary .widget-49-date-month {
  color: #dde1e9;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-success {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #e8faf8;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-success .widget-49-date-day {
  color: #17d1bd;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-success .widget-49-date-month {
  color: #17d1bd;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ebf7ff;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-info .widget-49-date-day {
  color: #36afff;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-info .widget-49-date-month {
  color: #36afff;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-warning {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: floralwhite;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-warning .widget-49-date-day {
  color: #ffc868;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-warning .widget-49-date-month {
  color: #ffc868;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-danger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #feeeef;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-danger .widget-49-date-day {
  color: #f95062;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-danger .widget-49-date-month {
  color: #f95062;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-light {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fefeff;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-light .widget-49-date-day {
  color: #f7f9fa;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-light .widget-49-date-month {
  color: #f7f9fa;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-dark {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ebedee;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-dark .widget-49-date-day {
  color: #394856;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-dark .widget-49-date-month {
  color: #394856;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-base {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f0fafb;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-base .widget-49-date-day {
  color: #68cbd7;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-base .widget-49-date-month {
  color: #68cbd7;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-49 .widget-49-title-wrapper .widget-49-meeting-info {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.widget-49 .widget-49-title-wrapper .widget-49-meeting-info .widget-49-pro-title {
  color: #3c4142;
  font-size: 19px;
}

.widget-49 .widget-49-title-wrapper .widget-49-meeting-info .widget-49-meeting-time {
  color: #a8a8a8;
  font-size: 17px;
}

.widget-49 .widget-49-meeting-points {
  font-weight: 400;
  font-size: 18px;
  margin-top: 0.5rem;
}

.widget-49 .widget-49-meeting-points .widget-49-meeting-item {
  display: list-item;
  color: #727686;
}

.widget-49 .widget-49-meeting-points .widget-49-meeting-item span {
  margin-left: 0.5rem;
}

.widget-49 .widget-49-meeting-action {
  text-align: right;
}

.widget-49 .widget-49-meeting-action a {
  text-transform: uppercase;
}

.historical-parraf {
  padding-left: 5px;
}

.widgetcard-margin {
  border-top: 11px solid blue;
}

.widget-49-date-primary {
  margin-top: -3px;
}

.widget-49-pro-title-cause {
  margin: 17px 0 -12px 6px;
  padding: 0 0 0 0;
}

p.historical-parraf {
  font-weight: 300 !important;
  line-height: 22px !important;
}

// Detail HistoricalTimeLine
/* PROJECTS */
.project-people,
.project-actions {
  text-align: right;
  vertical-align: middle;
}

dd.project-people {
  text-align: left;
  margin-top: 5px;
}

.project-people img {
  width: 32px;
  height: 32px;
}

.project-title a {
  font-size: 14px;
  color: #676a6c;
  font-weight: 600;
}

.project-list table tr td {
  border-top: none;
  border-bottom: 1px solid #196292;
  padding: 15px 10px;
  vertical-align: middle;
}

.project-manager .tag-list li a {
  font-size: 10px;
  background-color: rgb(243, 0, 0);
  padding: 5px 12px;
  color: inherit;
  border-radius: 2px;
  border: 1px solid #e7eaec;
  margin-right: 5px;
  margin-top: 5px;
  display: block;
}

.project-files li a {
  font-size: 11px;
  color: #676a6c;
  margin-left: 10px;
  line-height: 22px;
}

/* PROFILE */
.profile-content {
  border-top: none !important;
}

.profile-stats {
  margin-right: 10px;
}

.profile-image {
  width: 120px;
  float: left;
}

.profile-image img {
  width: 96px;
  height: 96px;
}

.profile-info {
  margin-left: 120px;
}

.feed-activity-list .feed-element {
  border-bottom: 1px solid #ece7e8;
}

.feed-element {
  //   padding-bottom: 15px;
  padding-top: 5px;
}

.feed-element,
.feed-element .media {
  margin-top: 15px;
}

.feed-element,
.media-body {
  overflow: hidden;
}

.feed-element > .pull-left {
  margin-right: 10px;
}

.feed-element img.img-circle,
.dropdown-messages-box img.img-circle {
  width: 38px;
  height: 38px;
}

.feed-element .well {
  //   border: 1px solid #e7eaec;
  box-shadow: none;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 10px 20px;
  font-size: 11px;
  line-height: 16px;
}

.feed-element .actions {
  margin-top: 10px;
}

.feed-element .photos {
  margin: 10px 0;
}

.feed-photo {
  max-height: 180px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 10px;
}

.file-list li {
  padding: 5px 10px;
  font-size: 11px;
  border-radius: 2px;
  //   border: 1px solid #e7eaec;
  margin-bottom: 5px;
}

.file-list li a {
  color: inherit;
}

.file-list li a:hover {
  color: #1ab394;
}

.user-friends img {
  width: 42px;
  height: 42px;
  margin-bottom: 5px;
  margin-right: 5px;
}

.ibox {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0;
}

.ibox.collapsed .ibox-content {
  display: none;
}

.ibox.collapsed .fa.fa-chevron-up:before {
  content: '\f078';
}

.ibox.collapsed .fa.fa-chevron-down:before {
  content: '\f077';
}

.ibox:after,
.ibox:before {
  display: table;
}

.ibox-title {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background-color: #ffffff;
  border-image: none;
  border-style: solid solid none;
  border-width: 3px 0 0;
  color: inherit;
  margin-bottom: 0;
  padding: 14px 15px 7px;
  min-height: 48px;
}

.ibox-content {
  background-color: #ffffff;
  color: inherit;
  padding: 15px 20px 20px 20px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0;
}

.ibox-footer {
  color: inherit;
  border-top: 1px solid #e7eaec;
  font-size: 90%;
  background: #ffffff;
  padding: 10px 15px;
}

ul.notes li,
ul.tag-list li {
  list-style: none;
}

// DETALLE
.img-circle {
  border-radius: 50%; // set radius in percents
}

.dl-horizontal dt {
  float: left;
  width: 172px;
  overflow: hidden;
  clear: left;
  text-align: right;
  text-overflow: initial;
  white-space: nowrap;
}

dt {
  font-weight: 700;
}

dd,
dt {
  line-height: 1.42857143;
}

dt {
  display: block;
}

@media (min-width: 768px) {
  .dl-horizontal dd {
    margin-left: 180px;
  }
}

// 2

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  color: #555;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
}

.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
}

.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
}

li {
  text-align: -webkit-match-parent;
}

.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}

.nav > li {
  position: relative;
  display: block;
}

.feed-element .well {
  border: 1px solid #e7eaec;
  box-shadow: none;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 16px;
}

.well {
  min-height: 20px;
  padding: 19px 19px 19px 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
}

.hr-stars {
  border: 1px solid green;
}

// Carrusel

.carousel-content-dndcontext {
  display: grid;
  justify-content: center;
  height: 100%;
  margin-bottom: 0px;
}

.carousel-div-dndcontext {
  display: block;
  align-items: center;
  margin-bottom: 50px;
  margin-top: -30px;
}

.carousel-label-dndcontext {
  font-size: 24px;
  margin: 14px 3px 7px 9px;
  padding-bottom: 5px;
}

.carousel-div-dndcontext-div {
  display: inline-flex;
  flex-direction: row;
}

.flashcard-container-selected-circle {
  border-radius: 50%;
  background-color: #ffffff;
  padding: 6px 0px 0 12px;
  font-size: 25px;
  font-weight: 500;
  width: 40px;
  height: 40px;
  cursor: pointer;
  top: 7px;
  right: 18px;
  box-shadow: 4px 5px 11px -5px #515151;
  position: absolute;
  z-index: 999;
}

.carousel-div-contents {
  display: contents;
  margin: 12px;
}

.carousel-input-filter {
  display: table-cell;
  width: 320px;
  margin: 12px;
}

.carousel-button-submmit {
  margin: 13px 13px 13px 15px;
}

.carousel-display-flex {
  display: flex;
}

.carousel-div-dnddraggable {
  user-select: none;
  margin: 0 10px 8px 0;
  height: 324px;
  width: 235px;
  border-radius: 8px;
  color: white;
}
